<template>
    <v-app>
        <loading v-if="loading" color="#545859" />
        <router-view />
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            display: true,
            loading: false,
        };
    },
    async mounted() {},
};
</script>
