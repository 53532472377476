import Vue from 'vue'
import VueCookies from 'vue-cookies'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import vuetify from '@/plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import App from './App.vue'
import router from './router'
import store from './store'
import ComponentRegistration from "./utility/components-registration"

import "@/styles/index.css"

const optionsToast = {
    timeout: 2000,
    closeOnClick: true,
    pauseOnHover: true,
}

Vue.config.productionTip = false
ComponentRegistration(Vue)
library.add(faChevronLeft, faAngleRight)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueCookies)
Vue.use(Toast, optionsToast)

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

async function runner() {
    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App),
    }).$mount('#app')
}

runner();